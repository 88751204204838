import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import ReactDOM from 'react-dom';

import ReactDOMServer from 'react-dom/server';

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
import testimonialImage from "../Assets/Images/testimonial.jpg";
import scheduledImage from "../Assets/Images/scheduledImage.png";
import InviteTeamImage from "../Assets/Images/InviteTeamImage.png";
import quotationImage from "../Assets/Images/quotation.svg";
import InviteInfo from "../Components/InviteInfo";
import Testimonial from "../Components/Testimonial";
import Resources from "../Components/Resources";
import DonutChart from "../Components/DonutChart";
import SendInvite from "../Components/SendInvite";
// import DonutChartAccordion from "../Components/DonutChartAccordion";
import { Button, Accordion, AccordionSummary, AccordionDetails, Box, Typography, LinearProgress, CircularProgress, Switch, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, CircleOutlined, CircleTwoTone, ExpandMore, Remove, WbSunnyOutlined } from "@mui/icons-material";
import sampleDashboardTeamImg from '../Assets/Images/sample-dashboard-team-img.png';
import sampleDashboardTeamImgMobile from '../Assets/Images/sample-dashboard-team-img-mobile.png';
import sampleDashboardIndividualImg from '../Assets/Images/sample-dashboard-individual-img.png';
import sampleDashboardIndividualImgMobile from '../Assets/Images/sample-dashboard-individual-img-mobile.png';
import CopyImg from '../Assets/Images/copycontent.svg';
import ImportantImg from '../Assets/Images/importantsign.svg';
import shadowIcon from '../Assets/Images/shadow.svg'
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { sectionsText } from "../globalFunctions";
import Promo from "../Components/Promo/Promo";
import HorizontalBarCharts from "../Components/HorizontalBarCharts";
import ResultsText from "../Components/ResultsText";
import ClickableCircularProgressBars from "../Components/ClickableCircularProgressBars";
import resultsImg from '../Assets/Images/results.jpg'
import holySpiritImage from '../Assets/Images/holy-spirit.svg'
import evangelizationImage from '../Assets/Images/evangelization.svg'
import leadershipImage from '../Assets/Images/leadership.svg'
import parishPray from '../Assets/Images/parish-pray.svg'
import parishCross from '../Assets/Images/parish-cross.svg'
import parishGrow from '../Assets/Images/parish-increase.svg'
import parishRead from '../Assets/Images/parish-read.svg'
import parishInvest from '../Assets/Images/parish-invest.svg'
import parishSupport from '../Assets/Images/parish-support.svg'
import Scores from "../Components/Scores/Scores";
import MilestoneBar from "../Components/MilestoneBar";
import AssessmentQuestionContext from "../Store/AssessmentQuestionContext";
import DesignationContext from "../Store/DesignationContext";
import StewardInner from '../Assets/Images/StewardInner.svg'
import ParishionerType from "../Components/ParishionerType/ParishionerType";
import { resultStyle } from "../Components/htmlRender/htmlResults";

//images
import steward from '../Assets/Images/steward-new.png';
import shipOfficer from '../Assets/Images/ship-officer-new.png';
import helm from '../Assets/Images/helm-new.png';
import sailor from '../Assets/Images/sailor-new.png';
import deckhand from '../Assets/Images/deckhand-new.png';
import passenger from '../Assets/Images/passenger-new.png'


import { renderToString } from 'react-dom/server'
import { translate } from "../Components/translate/translate";
import AuthContext from "../Store/AuthContext";
import LanguageContext from "../Store/LanguageContext";
const Results = (props) => {
  const componentRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState('');
  let navigate = useNavigate();
  const { id } = useParams();
  // const userType = window.localStorage.getItem("userType");
  const langCtx = useContext(LanguageContext)
  const authCtx = useContext(AuthContext)
  const assessmentCtx = useContext(AssessmentQuestionContext)
  const designationCtx = useContext(DesignationContext)
  const [userType, setUserType] = useState(designationCtx.designation);
  const { windowWidth } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([])
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScore7, setSubScore7] = useState(0);
  const [subScore8, setSubScore8] = useState(0);
  const [subScore9, setSubScore9] = useState(0);
  const [subScore10, setSubScore10] = useState(0);
  const [subScores, setSubScores] = useState();
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [textCopied, setTextCopied] = useState(false)
  const [sectionsData, setSectionsData] = useState();
  const [sectionScores, setSectionScores] = useState([0, 0, 0, 0]);
  const [variables, setVariables] = useState([]);
  const [resultText, setResultText] = useState(1);
  const [parentHash, setParentHash] = useState("");

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "resultPageDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const resultPeoplePatternData = [
    {
      "tabName": "Mentor",
      "desc": "<p>One of your Middle Grounds is the Mentor Pattern. With some concentration and effort, you can coach and develop others.</p><p>Mentors are people who like to come alongside you and develop you to be your best. They hone who you are, fanning the flame of your ability. Mentors build into you and help you realize your potential. They coach and guide you to help you discover your whole self. As trusted allies, they are friendly faces who encourage you to grow, develop and change.</p><p>All these Mentor qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Administrator",
      "desc": "<p>One of your Middle Grounds is the Administrator Pattern. With some concentration and effort, you can be good with tactics, details, and following the plan.</p><p>Administrators help establish the plan and then follow it as it is laid out. They like policy and structure, and are good with details and budgets. Administrators focus on management and tend to be averse to taking risks. They are good with procedures and like to follow the rules.</p><p>All these Administrator qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Catalyst",
      "desc": "<p>One of your Middle Grounds is the Catalyst Pattern. With some concentration and effort, you can be an innovator.</p><p>Catalysts are people who will challenge you, and so take you to new ground. They innovate and experiment in life, motivating you to change. Catalysts seem to light fires under people, spurring them on to unconventional or entirely new methods. They can be revolutionary, stirring up people. As trusted critics, we know their words are speaking truth as they speed up change in our lives.</p><p>All these Catalyst qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Relational",
      "desc": "<p>One of your Middle Grounds is the Relational Pattern. With some concentration and effort, you can be a great team builder.</p><p>Relationals are people who are motivated by harmony and see the team as crucial. They want people to be whole and healthy, and so are good team builders. Relationals highly value grace and mercy, and can be quite loving and warm. People deeply matter to them. Decisions are guided by deep-seated values and personal connections to others.</p><p>All these Relational qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Overseer",
      "desc": "<p>One of your Middle Grounds is the Overseer Pattern. With some concentration and effort, you can focus on strategy.</p><p>Overseers like to see the big picture. They work best with principles and concepts as their guiding force. Overseers prefer to work on strategy, aligning people with their right places to work. They think in terms of the vision and direction of things, tending to look at the horizon rather than at what is immediately in front of them.</p><p>All these Overseer qualities are within your grasp and will come with moderate effort.</p>"
    }
  ];

  useEffect(() => {
    document.body.style.backgroundColor = "#ffffff";
    fetchResults();
  }, []);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  useEffect(() => {
    if (apiData?.variables) {
      console.log(apiData?.variables)
      setVariables(apiData?.variables);
      let total = apiData?.variables?.find((obj) => obj.variable === "totalscorenon");
      if (total) {
        if (total.score < 60) {
          setScore(0);
        } else if (total.score < 70) {
          setScore(1);
        } else if (total.score < 75) {
          setScore(2);
        } else if (total.score < 80) {
          setScore(3);
        } else if (total.score < 85) {
          setScore(4);
        } else {
          setScore(5);
        }
      }

      let sub1 = apiData?.variables?.find((obj) => obj.variable === "expavg");
      if (sub1) { setSubScore1(sub1.score); }
      let sub2 = apiData?.variables?.find((obj) => obj.variable === "discernavg");
      if (sub2) { setSubScore2(sub2.score); }
      let sub3 = apiData?.variables?.find((obj) => obj.variable === "respondavg");
      if (sub3) { setSubScore3(sub3.score); }
      let sub4 = apiData?.variables?.find((obj) => obj.variable === "primacyavg");
      if (sub4) { setSubScore4(sub4.score); }
      let sub5 = apiData?.variables?.find((obj) => obj.variable === "resourceavg");
      if (sub5) { setSubScore5(sub5.score); }
      let sub6 = apiData?.variables?.find((obj) => obj.variable === "culturalavg");
      if (sub6) { setSubScore6(sub6.score); }
      let sub7 = apiData?.variables?.find((obj) => obj.variable === "visionavg");
      if (sub7) { setSubScore7(sub7.score); }
      let sub8 = apiData?.variables?.find((obj) => obj.variable === "planningavg");
      if (sub8) { setSubScore8(sub8.score); }
      let sub9 = apiData?.variables?.find((obj) => obj.variable === "leadingavg");
      if (sub9) { setSubScore9(sub9.score); }
      let sub10 = apiData?.variables?.find((obj) => obj.variable === "growingavg");
      if (sub10) { setSubScore10(sub10.score); }

      if (designationCtx.designation !== 'parishioner') {
        let holyspirit = apiData?.variables?.find((obj) => obj.variable === "holyspirit");
        let evangelism = apiData?.variables?.find((obj) => obj.variable === "evangelism");
        let leadership = apiData?.variables?.find((obj) => obj.variable === "leadership");

        if (holyspirit && evangelism && leadership) {

          if (holyspirit.score > 59 && evangelism.score > 59 && leadership.score > 59) {
            setResultText(1);
          } else if (holyspirit.score > 59 && evangelism.score < 60 && leadership.score > 59) {
            setResultText(2);
          } else if (holyspirit.score < 60 && evangelism.score > 59 && leadership.score > 59) {
            setResultText(3);
          } else if (holyspirit.score > 59 && evangelism.score > 59 && leadership.score < 60) {
            setResultText(4);
          } else if (holyspirit.score > 59 && evangelism.score < 60 && leadership.score < 60) {
            setResultText(5);
          } else if (holyspirit.score < 60 && evangelism.score > 59 && leadership.score < 60) {
            setResultText(6);
          } else if (holyspirit.score < 60 && evangelism.score < 60 && leadership.score > 59) {
            setResultText(7);
          } else if (holyspirit.score < 60 && evangelism.score < 60 && leadership.score < 60) {
            setResultText(8);
          }

        }
      } else {
        let holyspirit = apiData?.variables?.find((obj) => obj.variable === "exp0");
        let evangelism = apiData?.variables?.find((obj) => obj.variable === "resource0");
        let leadership = apiData?.variables?.find((obj) => obj.variable === "vision0");

        if (holyspirit && evangelism && leadership) {

          if (holyspirit.score > 3 && evangelism.score > 3 && leadership.score > 3) {
            setResultText(1);
          } else if (holyspirit.score > 3 && evangelism.score < 4 && leadership.score > 3) {
            setResultText(2);
          } else if (holyspirit.score < 4 && evangelism.score > 3 && leadership.score > 3) {
            setResultText(3);
          } else if (holyspirit.score > 3 && evangelism.score > 3 && leadership.score < 4) {
            setResultText(4);
          } else if (holyspirit.score > 3 && evangelism.score < 4 && leadership.score < 4) {
            setResultText(5);
          } else if (holyspirit.score < 4 && evangelism.score > 3 && leadership.score < 4) {
            setResultText(6);
          } else if (holyspirit.score < 4 && evangelism.score < 4 && leadership.score > 3) {
            setResultText(7);
          } else if (holyspirit.score < 4 && evangelism.score < 4 && leadership.score < 4) {
            setResultText(8);
          }

        }
      }

      // const sectionData1 = apiData?.variables?.find((obj) => obj.variable === "priority_total");
      // const sectionData2 = apiData?.variables?.find((obj) => obj.variable === "practising_total");
      // const sectionData3 = apiData?.variables?.find((obj) => obj.variable === "modelling_total");
      // const sectionData4 = apiData?.variables?.find((obj) => obj.variable === "promotion_total");
      // const scoreTotal = apiData?.variables?.find((obj) => obj.variable === "score_total");
      // // setScore(scoreTotal.score);
      // setSectionScores([sectionData1.score, sectionData2.score, sectionData3.score, sectionData4.score]);
      // setSubScore1(sectionData1.score);
      // setSubScore2(sectionData2.score);
      // setSubScore3(sectionData3.score);
      // setSubScore4(sectionData4.score);
      // setSubScores([sectionData1.score, sectionData2.score, sectionData3.score, sectionData4.score]);
    }
  }, [apiData]);

  useEffect(() => {
    if (componentRef.current) {
      setHtmlContent(componentRef.current.innerHTML);
      console.log(htmlContent)
    }
  });

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
      setTextCopied(true)
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log('assessment data', data);

          setApiData(data);
          setUserType(data.designation)
          setName(data.name);
          // setScore(data.score);
          // setScore(data.variables?.find((obj) => obj.variable === "score_total")?.score || 0);
          setAnswers(data?.answers);
          setSections(data.sections);

          setIsMember(data.isMember);
          // console.log(data);
          if (designationCtx.priestHash && designationCtx.designation === 'leader') {
            setParentHash(designationCtx.priestHash);
          } else {
            setParentHash(id);
          }

          if (data.designation && data.designation != userType) {
            designationCtx.updateDesignation(data.designation);
          }

          setTimeout(() => {
            setIsLoading(false);
          }, 1000);

        } else {
          // setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch:', error);
        // setOpenSnackbar(true);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

      });

  };

  const finalHTML = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title>HTML 5 Boilerplate</title>
      ${resultStyle}
    </head>
    <body>
    ${htmlContent}
    </body>
  </html>
  `

  const onDownloadPDF = async () => {
    setIsDownloading(true)
    const response = await fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/generateDRReport", {
      method: "POST",
      headers: {
        "Content-Type": "text/html"
      },
      body: finalHTML,
    });
    const url = await response.json()
    const pdfUrl = await url.reportURL;
    const link = document.createElement("a");
    console.log(url);
    link.href = pdfUrl;
    link.download = "Result.pdf";
    link.target = "_blank";

    setTimeout(() => {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloading(false);
    }, 2000);
  }

  const showParishionerResultText = () => {
    switch (resultText) {

      case 1: return <><h1>{handleTranslate("According to your results you are a Ship’s Officer", "ship-officer-heading")}</h1>
        <p>
          {handleTranslate("Like a ship’s officer, you see your leadership ability as something that could be offered and beneficial to the parish’s journey toward mission. Your openness to the Holy Spirit and your desire to evangelize mean you are aligned with the parish mission to “make disciples” and may be able to serve in several different ministries.", "ship-officer-info")}
        </p></>;

      case 2: return <><h1>{handleTranslate("According to your results you are a Helm", "helm-heading")}</h1>
        <p>
          {handleTranslate("Like the helm officer on a ship, the way you see your leadership and your openness to the Holy Spirit suggest that you may have an aptitude for steering things in the right direction. This could be in support of parish leadership and the vision for the parish, or possibly overseeing a ministry.", "helm-info")}
        </p>
      </>;

      case 3: return <><h1>{handleTranslate("According to your results you are a Steward", "steward-heading")}</h1>
        <p>
          {handleTranslate("Like the chief steward on a ship, the way you see your leadership ability and your desire for evangelization suggests you have strengths in welcoming people and making them feel at home. This is crucial in a missional parish. Serving on evangelization teams, programs like Alpha, or in parish hospitality may be a good fit for you.", "steward-info")}        </p>
      </>;

      case 4: return <><h1>{handleTranslate("According to your results you are a Sailor", "sailor-heading")}</h1>
        <p>
          {handleTranslate("Sailors are the backbone of the ship’s crew and work in many different areas. You see your relationship with the Holy Spirit and your desire to evangelize as strengths that can serve your parish. Areas like prayer ministry, evangelization teams, or programs like Alpha may be a good fit for you.", "sailor-info")}
        </p>
      </>;

      case 5: return <><h1>{handleTranslate("According to your results you are a Deckhand", "deckhand-heading")}</h1>
        <p>{handleTranslate("Deckhands are the ship’s crew.  You see your experience of the Holy Spirit as a strength that can contribute to the mission of your parish.  Working in prayer ministry, in particular, may be a good fit for you.", "deckhand-holy-spirit-info")}
        </p>
      </>;

      case 6: return <><h1>{handleTranslate("According to your results you are a Deckhand", "deckhand-heading")}</h1>
        <p>{handleTranslate("Deckhands are the ship’s crew.  You see your desire for evangelization as a strength that can contribute to the mission of your parish.  Working in evangelization programs, in particular, may be a good fit for you.", "deckhand-evangelization-info")}
        </p>
      </>;

      case 7: return <><h1>{handleTranslate("According to your results you are a Deckhand", "deckhand-heading")}</h1>
        <p>{handleTranslate("Deckhands are the ship’s crew. You see your leadership ability as a strength that can contribute to the mission of your parish.  Working on organizing events or programs may be a good fit for you.", "deckhand-leadership-info")}
        </p>
      </>;

      case 8: return <><h1>{handleTranslate("According to your results you are a Passenger", "passenger-heading")}</h1>
        <p>{handleTranslate("Like a ship’s passenger, you seem to appreciate being on board and want to get to your destination, but you may want to look at your parish’s strengths to see where there is room for you to grow in service.", "passenger-info")}
        </p>
      </>;

    }

  }

  const showParishionerResultImage = () => {
    switch (resultText) {

      case 1: return <img src={shipOfficer} alt="logo" className="banner-inner-logo" />

      case 2: return <img src={helm} alt="logo" className="banner-inner-logo" />

      case 3: return <img src={steward} alt="logo" className="banner-inner-logo" />

      case 4: return <img src={sailor} alt="logo" className="banner-inner-logo" />

      case 5: return <img src={deckhand} alt="logo" className="banner-inner-logo" />

      case 6: return <img src={deckhand} alt="logo" className="banner-inner-logo" />

      case 7: return <img src={deckhand} alt="logo" className="banner-inner-logo" />

      case 8: return <img src={passenger} alt="logo" className="banner-inner-logo" />

    }

  }

  const showPriestLeaderResultsText = () => {
    if (score == 0) {
      return <>
        <h1>{handleTranslate("You seem to perceive your parish as Onlookers", "onlookers")}</h1>
        <p>
          {handleTranslate("You seem to perceive your parish as in the early stages of renewal and is experiencing a healthy discontent with how things are. It seems that as they observe from a distance, your parish leaders are cautious and, at the same time, intrigued about renewal in other parishes. While there might be some hesitation to change, there is also an attraction to the potential for positive transformation. There is a growing desire for change, and a belief that things can be different is beginning to take root.", "onlookers-data")}
        </p>
      </>
    }
    else if (score == 1) {
      return <>
        <h1>{handleTranslate("You seem to perceive your parish as On the Shore", "on-the-shore")}</h1>
        <p>
          {handleTranslate('You seem to perceive your parish as moving on to a stage where priests and leaders acknowledge that renewal is possible and worth trying. It seems that your parish leaders are more aware of their holy discontent and deep longing for change but need more inspiration and encouragement to decide on and fully embrace renewal. Their mindset is shifting from "things can be different" to "things will be different."', "on-the-shore-data")}         </p>
      </>
    }
    else if (score == 2) {
      return <>
        <h1>{handleTranslate("You seem to perceive your parish as Casting Off", "casting-off")}</h1>
        <p>
          {handleTranslate("You seem to perceive your parish as actively on the parish renewal journey. While not all of the details have been figured out, the decisive choice to change has already been made. Currently, you seem to perceive your parish as fully involved in shaping a vision, identifying allies, and investing in the three keys that unlock renewal in a parish.", "casting-off-data")}
        </p>
      </>
    }
    else if (score == 3) {
      return <>
        <h1>{handleTranslate("You seem to perceive your parish as Raising the Sails", "raising-the-sails")}</h1>
        <p>
          {handleTranslate("You seem to perceive your parish as now reaping the benefits of prior changes. Currently, you seem to perceive that following the decision to pursue renewal, your parish is putting together the necessary elements for the mission. Even though there might be pushback and chaos, you may be seeing the early fruits and lives changing, and they might be inspiring you to keep going. Addressing an unhealthy culture triggers some resistance, and the realization that change is not fleeting begins to settle in.", "raising-the-sails-data")}
        </p>
      </>
    }
    else if (score == 4) {
      return <>
        <h1>{handleTranslate("You seem to perceive your parish as Sailing the Waves", "sailing-the-waves")}</h1>
        <p>
          {handleTranslate("You seem to perceive your parish as thriving. It seems that through the power of the Holy Spirit, applying leadership principles, and prioritizing evangelization, your parish is actively making new disciples. As you continue to bear fruit, you also encounter new challenges: burnout becomes a potential concern, and you see the need to implement structural changes to keep the mission going.", "sailing-the-waves-data")}        </p>
      </>
    }
    else if (score == 5) {
      return <>
        <h1>{handleTranslate("You seem to perceive your parish as Beacon Parishes", "beacon-parish")}</h1>
        <p>
          {handleTranslate("You seem to perceive your parish as actively shaping a plan for long-term health and fruitfulness. It seems that your parish cast a vision, formed a leadership team, established an evangelization program, and developed a leadership pipeline—all part of an active parish renewal journey. Continuous discernment, pruning, and refining of structures, processes, and tools for the mission are ongoing. Your parish is on its way toward becoming an influential multiplier and movement leader, guiding other parishes in this transformative journey.", "beacon-parish-data")}
        </p>
      </>
    }
  }

  const showHeaderContent = () => (userType === "parishioner" ?
    <>
      <div className="banner-outer-container">
        <div className="banner-container">
          {showParishionerResultImage()}
        </div>
      </div>
      <div className="result-details-container-header-content">
        <h1 className="name" >{name}</h1>
        {showParishionerResultText()}

      </div>
      <div className="result-details-container-header-content bg-class">
        <h1 className="name" >{name}</h1>
        <h1>{handleTranslate("Thank you for completing the Missional Waypoint Tool!", "thank-you-for-completing-the-missional-waypoint-tool")}</h1>
        <p>
          {handleTranslate("Your responses will help your parish identify where it is on its missional journey and help the parish leaders discover the way forward. But parish renewal only happens in the power of God, and your continued prayer for the pastor and the parish leaders is one of the most powerful tools you have.", "missional-waypoint-tool-info-para-1")}
        </p>
        <p>{handleTranslate("Parish renewal doesn’t usually happen overnight, so the six stages of becoming missional help identify where your parish is on the journey, what challenges to expect and what to look forward to.", "missional-waypoint-tool-info-para-2")}</p>
        <p className="pdfheight">{handleTranslate("Many parishes have travelled ahead on this path and are willing and able to help you on your own parish journey. Divine Renovation Ministry can help connect you with other parishes and provide resources and accompaniment to help you on your way.", "missional-waypoint-tool-info-para-3")}</p>
      </div>
    </>
    :
    <div className="result-details-container-header-content">
      <h1 className="name" >{name}</h1>
      {console.log("resultText", resultText)}
      {showPriestLeaderResultsText()}
    </div>
  );

  const getResultReportScore = (resultSectionType) => {

    let holyspirit = apiData?.variables?.find((obj) => obj.variable === "holyspirit");
    let evangelism = apiData?.variables?.find((obj) => obj.variable === "evangelism");
    let leadership = apiData?.variables?.find((obj) => obj.variable === "leadership");

    let typeScore = 0;

    switch (resultSectionType) {
      case "holyspirit":
        typeScore = holyspirit?.score;
      case "evangelism":
        typeScore = evangelism?.score;
      case "leadership":
        typeScore = leadership?.score;
    }

    if (typeScore < 33) {
      return <p className="low-score">{handleTranslate("Needs Work", "needs-work")}</p>
    }
    else if (typeScore >= 33 && typeScore <= 66) {
      return <p className="medium-score">{handleTranslate("Getting there", "getting-there")}</p>
    }
    else if (typeScore > 66) {
      return <p className="high-score">{handleTranslate("Doing Well", "doing-well")}</p>
    }
  }

  console.log("apiData?.topQuestions", apiData?.topQuestions )
  console.log("apiData?.bottomQuestion", apiData?.bottomQuestions )
  

  const getStrengthTranslatedQuestion = () => {

    const apiDataArray = [
      ...(apiData?.topQuestions || []),
      ...(apiData?.bottomQuestions || [])
    ]

    console.log("apiDataArray", apiDataArray )

    const strengthQuestionsInfo = (question) => {
      if (
        question?.id === 1078 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("You recognize the experience of the transformational power of the Holy Spirit in your parish.", "que-one-positive-statement")}`;
      } 
      else if (
        question?.id === 1079 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your recognition and appreciation of the gifts of the Holy Spirit within your own life or those around you greatly enrich your parish's spiritual journey.", "que-two-positive-statement")}`
      }
      else if (
        question?.id === 1084 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your ability to discern the voice of the Holy Spirit amidst life's complexities is a testament to your deep spiritual sensitivity within your parish.", "que-three-positive-statement")}`
      }
      else if (
        question?.id === 1085 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your intentional commitment to reflecting on the promptings of the Holy Spirit enriches your parish's collective discernment process.", "que-four-positive-statement")}`
      }
      else if (
        question?.id === 1087 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your dedication to differentiating between human wisdom and the wisdom of the Holy Spirit fosters a culture of spiritual depth within your parish.", "que-five-positive-statement")}`
      }
      else if (
        question?.id === 1088 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your willingness to take risks in alignment with the Holy Spirit's direction inspires and emboldens your parish.", "que-six-positive-statement")}`
      } 
      else if (
        question?.id === 1091 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your consistent demonstration of faith through courageous risks taken in pursuit of the Holy Spirit's guidance is a source of strength and inspiration for your parish.", "que-seven-positive-statement")}`
      }
      else if (
        question?.id === 1092 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your steadfast reliance on the Holy Spirit, evident in how you courageously face risks, is a cornerstone of your parish's spiritual vitality.", "que-eight-positive-statement")}`
      }
      else if (
        question?.id === 1099 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your commitment to prioritizing evangelization when planning programs reflects your dedication to spreading the good news within your parish.", "que-nine-positive-statement")}`
      }
      else if (
        question?.id === 1100 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your steadfast focus on presenting the good news in all our programs highlights your passion for sharing the message of Jesus within your parish.", "que-ten-positive-statement")}`
      }
      else if (
        question?.id === 1103 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your understanding that sharing the good news is a collective responsibility underscores your active participation in fostering a culture of evangelization within your parish.", "que-eleven-positive-statement")}`
      }
      else if (
        question?.id === 1107 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your belief in providing opportunities for others to respond to the good news demonstrates your commitment to nurturing spiritual growth and transformation within your parish.", "que-twelve-positive-statement")}`
      }
      else if (
        question?.id === 1126 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Your willingness to recognize areas for potential growth in your leadership abilities demonstrates a humble and reflective approach to serving your parish.", "que-thirteen-positive-statement")}`
      }
      else if (
        question?.id === 1127 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your self-awareness regarding your leadership skills and areas for growth reflects your commitment to continuous improvement and effective leadership within your parish.", "que-fourteen-positive-statement")}`
      }
      return null
    }

    const strengthMessages = apiDataArray
    .map(question => ({ question, message: strengthQuestionsInfo(question) }))
    .filter(entry => entry.message !== null); 

    if (strengthMessages.length === 0) {
      return <p style={{ padding: "0 33px", margin: "22px auto" }}>{handleTranslate("You see your parish as being very early on its journey to renewal. This is an exciting time, as your personal opportunities are endless as your parish grows. Spending time in prayer for your priest and parish will prepare you for the upcoming changes.", "generic-no-strength")}</p>
    }
    return (
      <div className="content-div">
        {strengthMessages.map((entry, index) => (
          <div className="content" key={index}>
            <img src={getIconImage(entry.question?.sectionTitle)} alt="icon" />
            <p style={{ color: '#000000' }}>{entry.message}</p>
          </div>
        ))}
      </div>
    );
  }
  
  const getWeaknessTranslatedQuestions = () => {

    const apiDataArray = [
      ...(apiData?.topQuestions || []),
      ...(apiData?.bottomQuestions || [])
    ]
    const weaknessQuestionInfo = (question) => {
      if (
        question.id === 1078 && 
        (question.answerText === "Strongly Disagree" || question.answerText === "Disagree")
      ) {
        return `${handleTranslate("Both personal and parish transformations are only possible through God’s grace and the power of the Holy Spirit. Look for opportunities to pray with others and for others to specifically ask the Holy Spirit to come into power.", "que-one-negative-statement")}`
      }
      else if (
        question?.id === 1079 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("The Holy Spirit is always active, but we also need to be looking for what God is doing in our own lives and in our parish. Actively look for what God is doing in your own life and in the lives of others, and identify it when you see it. Name it when you see it in your life and have conversations with those where you recognize the activity of the Holy Spirit.", "que-two-negative-statement")}`
      }
      else if (
        question?.id === 1084 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Because there are so many voices in this world that compete for our attention, it can be difficult to distinguish God’s voice from all the others. Taking time daily to discern His voice by being open, patient, and attentive to subtle promptings will make it easier over time. There are some wonderful resources to help with the process of discernment that you may wish to read or share with others.", "que-three-negative-statement")}`
      }
      else if (
        question?.id === 1085 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Listening to God’s voice is challenging, even when we actively seek it. Not taking time to specifically reflect on what God is saying to us makes it almost impossible. Personally, making that effort and asking for that time in groups or meetings can help to create a culture where that becomes the norm.", "que-four-negative-statement")}`
      }
      else if (
        question?.id === 1087 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("In our personal lives and in the life of our parish, it is important to recognize that what God is calling us to do may seem to be contrary to our own wisdom. Not leaving room for God and relying only on ourselves cannot lead to the kind of transformation God calls for. Create opportunities for yourself and for others to prayerfully discern if God is calling you to “think bigger” or “think differently” than what human wisdom alone can offer.", "que-five-negative-statement")}`
      }
      else if (
        question?.id === 1088 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Risk-taking, on its own, is not inherently virtuous, but when it aligns with the true discernment of the Spirit, it can be courageous. As Christians, we are called to live and dream courageously, not because of our own capability but because of our trust and reliance on God.", "que-six-negative-statement")}`
      } 
      else if (
        question?.id === 1091 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Risk-taking, on its own, is not inherently virtuous, but when it aligns with the true discernment of the Spirit, it can be courageous. As Christians, we are called to live and dream courageously, not because of our own capability but because of our trust and reliance on God.", "que-seven-negative-statement")}`
      }
      else if (
        question?.id === 1092 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Every person and every parish will face risks that are outside of their control. But if our mission and our vision are truly God-centered, we can face those risks with confidence and without compromising our identity.", "que-eight-negative-statement")}`
      }
      else if (
        question?.id === 1099 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Evangelization is the primary mission of the Church, and, while there are many ways to evangelize, being intentional about how the parish programs and services proclaim the Good News in everything is fundamental to a missional parish.", "que-nine-negative-statement")}`
      }
      else if (
        question?.id === 1100 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("In planning, we may consider how a particular program or service is a tool of evangelization, but it needs to be executed in that way as well. “What is the best way for you to present the Good News in your context?”", "que-ten-negative-statement")}`
      }
      else if (
        question?.id === 1103 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Sharing the Good News is a fundamental commandment of Jesus; it is called the Great Commission. But, along with this commission, if we have experienced the full joy of knowing Jesus in our own lives, how could we not want others to experience it as well? Programs like Alpha can make sharing Christ with others easier, but it requires everyone to invite and share the opportunity with others.", "que-eleven-negative-statement")}`
      }
      else if (
        question?.id === 1107 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Having a dedicated program for evangelization, like Alpha, can go a long way toward normalizing the encounter with Christ. But it cannot remain an isolated program. Praying for the participants as a parish, celebrating new encounters together, and having the active participation of many different parishioners will help make this a normal part of parish life.", "que-twelve-negative-statement")}`
      }
      else if (
        question?.id === 1126 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Parish renewal will only happen when the gifts and talents of parishioners are unleashed. Seeking out opportunities, even when they may not seem obvious, can help you and others ignite the renewal process.", "que-thirteen-negative-statement")}`
      }
      else if (
        question?.id === 1127 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("There are several tools that can help you identify your gifts and strengths, which can be developed to help you grow as a leader. If you haven’t already, consider taking the APEST assessment or StrengthsFinder.", "que-fourteen-negative-statement")}`
      }
      return null
    }

    const weaknessMessages = apiDataArray
    .map(question => ({ question, message: weaknessQuestionInfo(question) }))
    .filter(entry => entry.message !== null); 

    if (weaknessMessages.length === 0) {
      return <p style={{ padding: "0 33px", margin: "22px auto" }}>{handleTranslate("You see your parish as being very missional and far along its journey to renewal. Your parish has faced challenges as it has grown, and you've grown along with it. Keep encouraging your priest and leadership by continuing to pray for them.", "generic-no-growth-areas")}</p>
    }
    return (
      <div className="content-div">
        {weaknessMessages.map((entry, index) => (
          <div className="content" key={index}>
            <img src={getIconImage(entry.question?.sectionTitle)} alt="icon" />
            <p style={{ color: "#000000" }}>{entry.message}</p>
          </div>
        ))}
      </div>
    );
  }

  // const getStrengthQuestions = () => {

  //   const apiDataArray = apiData?.topQuestions.slice(0, 3);

  //   console.log('apiDataArray', apiDataArray)


  //   return apiDataArray?.map((el) => {
  //     return <div className="content-div">
  //       <img src={getIconImage(el.sectionTitle)} />
  //       <p>{el.question}</p>
  //     </div>
  //   })
  // }

  const getIconImage = (title) => {
    if (title === "Experience of the Holy Spirit" || title === "Asking & Discerning in Faith" || title === "Responding in Faith") {
      return "https://40parables-assets.s3.amazonaws.com/holy-spirit.svg";
    }
    if (title === "Resources Aligned" || title === "Primacy of Programs" || title === "Cultural Reinforcement") {
      return "https://40parables-assets.s3.amazonaws.com/evangelization.svg";
    }
    if (title === "Vision" || title === "Planning and Priorities" || title === "Leading out of a team" || title === "Growing Leadership Skills") {
      return "https://40parables-assets.s3.amazonaws.com/leadership.svg";
    }
  }

  const getWeaknessQuestions = () => {

    const apiDataArray = apiData?.bottomQuestions.slice(0, 3);;

    return apiDataArray?.map((el) => {
      return <div className="content-div">
        <img src={getIconImage(el.sectionTitle)} />
        <p>{el.question}</p>
      </div>
    })
  }

  const getProgressBarClassName = (scoreNo) => {
    if (scoreNo < 33) {
      return "low-score";
    }
    else if (scoreNo >= 33 && scoreNo <=80) {
      return "medium-score";
    }
    else if (scoreNo > 80) {
      return "high-score";
    }
  }

  const getParishSupport = () => {
    return <>
      <div className="parish-support">
        <div className="header">
          <h1>{handleTranslate("Feel your parish come alive", "feel-the-parish-heading")}</h1>
          <p className="parish-support-description">{handleTranslate("Here are three ways to make the most of the Missional Waypoint Tool within your parish", "feel-the-parish-description")}</p>
        </div>
        <div className="cards">
          <div className="card">
            <div className="image-container">
              <img src={"https://40parables-assets.s3.amazonaws.com/parish-pray.svg"} alt="" />
            </div>
            <h1>{handleTranslate("Pray for your parish", "pray-heading")}</h1>
            <p>{handleTranslate("As your parish journeys towards parish renewal, priests and lay leaders need the strength and encouragement that comes from praying the Rosary.", "pray-description")}</p>
            <Link to={"https://divinerenovation.org/free-materials/#rosary"} target="_blank">
              <button className="btn-cta">{handleTranslate("Pray", "pray-cta")}</button>
            </Link>
          </div>
          <div className="card">
            <div className="image-container">
              <img src={"https://40parables-assets.s3.amazonaws.com/parish-increase.svg"} alt="" />
            </div>
            <h1>{handleTranslate("Grow in your parish", "grow-heading")}</h1>
            <p>{handleTranslate("Find books and resources that support you in the work of parish renewal.", "grow-description")}</p>
            <Link to={"https://divinerenovation.org/books/"} target="_blank">
              <button className="btn-cta">{handleTranslate("Discover", "discover-cta")}</button>
            </Link>
          </div>
          <div className="card">
            <div className="image-container">
              <img src={"https://40parables-assets.s3.amazonaws.com/parish-cross.svg"} alt="" />
            </div>
            <h1>{handleTranslate("Reflect on your parish", "reflect-heading")}</h1>
            <p>{handleTranslate("Reflect on how you can help your parish bring people to Jesus.", "reflect-description")}</p>

            <Link to={"https://divinerenovation.org/3-keys-study-guide/"} target="_blank">
              <button className="btn-cta">{handleTranslate("Learn", "learn-cta")}</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="parish-support">
        <div className="header">
          <h1>{handleTranslate("Discover the transformation of parishes that are embracing mission", "discover-the-transformation")}</h1>
          <p className="parish-support-description">{handleTranslate("Here are three ways to learn from and support the mission outside of your parish", "discover-the-transformation-description")}</p>
        </div>
        <div className="cards">
          <div className="card">
            <div className="image-container">
              <img src={"https://40parables-assets.s3.amazonaws.com/parish-read.svg"} alt="" />
            </div>
            <h1>{handleTranslate("Read good news", "read-good-news")}</h1>
            <p>{handleTranslate("Leading change can be tough. But you are not alone! Get inspiring stories of hope and renewal delivered to your inbox.", "read-good-news-description")}</p>

            <Link to={"https://divinerenovation.org/newsletter/"} target="_blank">
              <button className="btn-cta">{handleTranslate("Sign Up", "sign-up-cta")}</button>
            </Link>
          </div>
          <div className="card">
            <div className="image-container">
              <img src={"https://40parables-assets.s3.amazonaws.com/parish-invest.svg"} alt="" />
            </div>
            <h1>{handleTranslate("Invest in yourself", "invest-in-yourself")}</h1>
            <p>{handleTranslate("Join an event to learn from others, grow in your leadership, and renew your hope.", "invest-in-yourself-description")}</p>
            <Link to={"https://divinerenovation.org/events/"} target="_blank">
              <button className="btn-cta">{handleTranslate("Register", "register-cta")}</button>
            </Link>
          </div>
          <div className="card">
            <div className="image-container">
              <img src={"https://40parables-assets.s3.amazonaws.com/parish-support.svg"} alt="" />
            </div>
            <h1>{handleTranslate("Support the mission", "support-the-mission")}</h1>
            <p>{handleTranslate("Your parish is taking steps to bring people to Jesus. Imagine thousands more becoming places where lives are transformed. With your help, they can.", "support-the-mission-description")}</p>
            <Link to={"https://divinerenovation.org/give/"} target="_blank">
              <button className="btn-cta">{handleTranslate("Give", "give-cta")}</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  }

  const getViewYourDashboard = () => {
    if (designationCtx.designation === 'leader') {
      return <>
        <div className="view-dashboard-container">
          <h1>{handleTranslate("See how your parish views itself", "view-your-dashboard-component-heading")}</h1>
          <p>{handleTranslate("View responses from your leadership and parishioners on a centralized dashboard.", "view-your-dashboard-component-info")}</p>
          <Link to={`/dashboard/` + parentHash + '/overview'} target="_blank">
            <button>{handleTranslate("View your Dashboard", "view-your-dashboard-cta")}</button>
          </Link>
          {/* <div className="temp-placeholder">
          <p>The link will be sent to you once your Scores are compiled</p>
        </div> */}
        </div>
      </>
    }
    else if (designationCtx.designation === 'priest') {
      return <>
        <div className="view-dashboard-container">
          <h1>{handleTranslate("Take a look at how your parish views itself", "view-your-dashboard-heading")}</h1>
          <p>{handleTranslate("View responses from your leadership and parishioners on a centralized dashboard.", "view-your-dashboard-info")}</p>
          <Link to={`/dashboard/` + parentHash + '/overview'} target="_blank">
            <button>{handleTranslate("View your Dashboard", "view-your-dashboard-cta")}</button>
          </Link>
          {/* <div className="temp-placeholder">
          <p>The link will be sent to you once your Scores are compiled</p>
        </div> */}
        </div>
      </>
    }
  }

  const getInviteParishionerLeader = (parentHash) => {

    const generateQRCodeSVGString = (value) => {
      const size = 1024;
      const padding = 100;
      const qrSize = size - (padding * 2);

      return ReactDOMServer.renderToStaticMarkup(
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="white" />
          <g transform={`translate(${padding}, ${padding})`}>
            <QRCode
              value={value}
              size={qrSize}
              level="H"
              renderas="svg"
            />
          </g>
        </svg>
      );
    };

    const downloadSVGAsPNG = (svgString, filename) => {
      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const img = new Image();
      img.src = svgUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(svgUrl);

        const pngUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngUrl;
        link.download = `${filename}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    };

    const leaderSvgString = generateQRCodeSVGString(`${window.location.host}/start/l/${parentHash}`);
    const parishionerSvgString = generateQRCodeSVGString(`${window.location.host}/start/p/${parentHash}`);

    return (
      <>
        <div className="invite-head" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <h1>
            {
              handleTranslate("Invite your parishioners and leaders to take this assessment", "qr-code-heading")
            }
          </h1>
          <p className="invite-para"
            dangerouslySetInnerHTML={{
              __html: handleTranslate(
                `Invite all your parishioners and leaders to take this assessment and view their individual and group scores to <br/> find out where you are aligned and where you need to align to maximize your Kingdom impact.`,
                "qr-code-sub-heading"
              )
            }}
          >
          </p>
        </div>

        <div className="invite-pl-container">
          <div className="container">
            <h1 className="f-28">{
              handleTranslate("For Leaders", "for-leaders")
            }</h1>
            <QRCode value={`${window.location.host}/start/l/${parentHash}`} size={256} style={{ height: "auto", maxWidth: "50%", width: "50%", marginLeft: '50%', transform: 'translateX(-50%)' }} />
            <div className="copy-url-container">
              <p className="download-qr" onClick={() => downloadSVGAsPNG(leaderSvgString, 'leader-qr')}>
                {handleTranslate("Download QR Code", "download-qr-code")}
              </p>
            </div>
            <p>{handleTranslate("or", "or")}</p>
            <div className="copy-url-container">
              <p className="copy-text" onClick={() => handleCopy(`${window.location.host}/start/l/${parentHash}`)}>
                {handleTranslate("Copy Link", "copy-link-text")}
              </p>
            </div>
          </div>
          <div className="dotted-line"></div>
          <div className="container">
            <h1 className="f-28">{
              handleTranslate("For Parishioners", "for-parishioners")
            }</h1>
            <QRCode value={`${window.location.host}/start/p/${parentHash}`} size={256} style={{ height: "auto", maxWidth: "50%", width: "50%", marginLeft: '50%', transform: 'translateX(-50%)' }} />
            <div className="copy-url-container">
              <p className="download-qr" onClick={() => downloadSVGAsPNG(parishionerSvgString, 'parishioner-qr')}>
                {handleTranslate("Download QR Code", "download-qr-code")}
              </p>
            </div>
            <p>{handleTranslate("or", "or")}</p>
            <div className="copy-url-container">
              <p className="copy-text" onClick={() => handleCopy(`${window.location.host}/start/p/${parentHash}`)}>
                {handleTranslate("Copy Link", "copy-link-text")}
              </p>
            </div>
          </div>
        </div>
        {
          textCopied === true ?
            <div className="copied-msg" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <p style={{ height: "46px", width: "120px", background: "#F0F0F0", color: "#000", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                Copied..!!
              </p>
            </div>
            : null
        }
        <div className="qr-recommend">
          <p>{handleTranslate("Use these QR codes to share the Missional Waypoint Tool with a large group in person.", "qr-recommend")}</p>
        </div>

      </>
    );
  }

  const getResultSectionReport = () => {
    return <div className="result-section-report">
      <div className="card">
        <div className="image-container">
          <img src={"https://40parables-assets.s3.amazonaws.com/holy-spirit.svg"} alt="" />
          <div className="section-heading">
            <h1>{handleTranslate("Power of the Holy Spirit", "holy-spirit")}</h1>
            <Tooltip title={handleTranslate("The parish endeavors for all people to have an experience of the power of the Holy Spirit. It actively discerns and trusts the Holy Spirit in all its decision-making.", "holy-spirit-tooltip")}>
              <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
            </Tooltip>
          </div>
          {getResultReportScore("holyspirit")}
        </div>
        <div className="inner-card">
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Experience of the Spirit", "experience-of-the-spirit")}
              <Tooltip title={handleTranslate("The parish and the lives of people in the parish have been transformed by the experience of the Holy Spirit.", "experience-of-the-spirit-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore1)}`} style={{ width: subScore1 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Asking & Discerning in Faith", "asking-and-discerning")}
              <Tooltip title={handleTranslate("There is an openness and sensitivity to hearing what the Holy Spirit would say to the parish beyond human wisdom. There is intentional time given to reflect and listen to the direction of the Spirit in vision, strategy, and practice within the parish.", "asking-and-discerning-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore2)}`} style={{ width: subScore2 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Responding in Faith", "responding-in-faith")}
              <Tooltip title={handleTranslate("Actions of risk, dependence, and full obedience to the Holy Spirit's direction are repeatedly demonstrated.", "responding-in-faith-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore3)}`} style={{ width: subScore3 + "%" }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image-container">
          <img src={"https://40parables-assets.s3.amazonaws.com/evangelization.svg"} alt="" />
          <div className="section-heading">
            <h1>{handleTranslate("Primacy of Evangelization", "evangelization")}</h1>
            <Tooltip title={handleTranslate("At the heart of everything the parish does is helping people inside and outside the parish hear the good news for themselves and choose to follow Jesus.", "evangelization-tooltip")}>
              <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
            </Tooltip>
          </div>
          {getResultReportScore("evangelism")}
        </div>
        <div className="inner-card">
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Resources Aligned", "resources-aligned")}
              <Tooltip title={handleTranslate("The resources of the parish are allocated disproportionally toward helping people inside and outside the parish hear the good news (like Alpha) for themselves and choose to follow Jesus.", "resources-aligned-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore4)}`} style={{ width: subScore4 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Primacy of Programs", "primacy-of-programs")}
              <Tooltip title={handleTranslate("The programs for evangelization are given priority in planning and execution within the parish.", "primacy-of-programs-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore5)}`} style={{ width: subScore5 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Cultural Reinforcement", "cultural-reinforcement")}
              <Tooltip title={handleTranslate("The disposition of the parish and the primary focus of the parish encourage everyone and everything in the parish to promote evangelization.", "cultural-reinforcement-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore6)}`} style={{ width: subScore6 + "%" }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image-container">
          <img src={"https://40parables-assets.s3.amazonaws.com/leadership.svg"} alt="" />
          <div className="section-heading">
          <h1>{handleTranslate("Best of Leadership", "leadership")}</h1>
          <Tooltip title={handleTranslate("The parish’s individual and collective gifts are unleashed, mobilized, and directed to bring God’s vision for the parish into reality.", "leadership-tooltip")}>
            <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
          </Tooltip>
          </div>
          {getResultReportScore("leadership")}
        </div>
        <div className="inner-card">
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Vision", "vision")}
              <Tooltip title={handleTranslate("Discerning, articulating, and casting vision in such a manner that it is clear where God is calling the parish.", "vision-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore7)}`} style={{ width: subScore7 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Planning and Priorities", "planning-and-priorities")}
              <Tooltip title={handleTranslate("Putting clear action steps together so that the priorities, systems, and next steps to fulfilling the vision become a reality.", "planning-and-priorities-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore8)}`} style={{ width: subScore8 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Leading out of a team", "leading-out-of-a-team")}
              <Tooltip title={handleTranslate("Sharing leadership through mobilizing others to lead out of their gifts and play their part in bringing the vision to life.", "leading-out-of-a-team-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore9)}`} style={{ width: subScore9 + "%" }}></div>
            </div>
          </div>
          <div className="report">
            <h1 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{handleTranslate("Growing Leadership Skills", "growing-leadership-skills")}
              <Tooltip title={handleTranslate("The ongoing development of one's leadership gifts as well as the leadership gifts of others.", "growing-leadership-skill-info")}>
                <img src="https://40parables-assets.s3.amazonaws.com/icons8-info.svg" style={{width:"16px",height:"16px"}}/>
              </Tooltip>
            </h1>
            <div className="progress-bar">
              <div className={`current-progress ${getProgressBarClassName(subScore10)}`}style={{ width: subScore10 + "%" }} ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  setTimeout(() => {
    setTextCopied(false)
  }, 2000)

  return (
    <>
      <div ref={componentRef}>
        <div className="container results">
          <div className="milestone-slider-and-banner-img-container">
            {
              userType !== 'parishioner' && <MilestoneBar location={score} from={'Results'} />
            }
            <img src={"https://40parables-assets.s3.amazonaws.com/results.jpg"} className="banner-img" alt="" />
          </div>
          <div className="results-details-container">
            {showHeaderContent()}

            {
              isLoading ? <>
                <div className="loading">
                  <CircularProgress size={60} />
                </div>
              </> : <>
                {userType !== "parishioner" && getResultSectionReport()}
                <div className="result-details-container result-details-positive" defaultExpanded={0}>
                  <div className="result-details-summary">
                    <p className="result-details-title">{handleTranslate("Observations of your strengths in your parish", "result-details-title")}</p>
                  </div>
                  <div className="result-details-content">
                    {getStrengthTranslatedQuestion()}
                  </div>
                </div>
                <div className="result-details-container result-details-negative" defaultExpanded={0}>
                  <div className="result-details-summary">
                    <p className="result-details-title">{handleTranslate("Areas in which you may want to grow", "result-details-title-2")}</p>
                  </div>
                  <div className="result-details-content">
                    {getWeaknessTranslatedQuestions()}
                  </div>
                </div>
              </>
            }

            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}><Button className="download-button" onClick={onDownloadPDF} sx={{
              border: "1px solid #84161D", width: "290px", color: "white", background: "#84161D", textTransform: "capitalize", borderRadius: "25px", padding: "10px 20px", fontSize: "21px", fontWeight: 700,textTransform: "none", ':hover': {
                background: '#97A97C',
                color: 'white',
                transition: '0.3s',
                border: '1px solid #97A97C'
              },
            }}>{isDownloading ? <CircularProgress sx={{ color: "white" }} /> : handleTranslate("Download My Results", "download-result-cta")}</Button></div>
            {/* <div className="download-results-cta-container">
            <button className="download-results-btn">Download My Results</button>
          </div> */}

            {
              userType === "priest" && getInviteParishionerLeader(parentHash)
            }

            {getViewYourDashboard()}

            {
              userType === 'parishioner' && getParishSupport()
            }

            {/* { userType === "parishioner" &&
         
          <ParishionerType />
          
        } */}

          </div>
        </div>
      </div>
    </>

  );
};

export default Results;